import { Navigate, useSearchParams } from '@solidjs/router';
import { Match, Switch } from 'solid-js';
import { Content } from '../../../../components/content';
import { GenericLayout } from '../../../../layouts/generic';
import ConfirmSubscription from '../../../../components/confirm-subscription';

export default function ConfirmationPage() {
	const [params] = useSearchParams<{ subscriptionId?: string; productId?: string }>();

	return (
		<Switch>
			<Match when={!params.subscriptionId || !params.productId}>
				<Navigate href="/access" />
			</Match>
			<Match when>
				<GenericLayout nav="minimal">
					<Content size="xsmall">
						<ConfirmSubscription subscriptionId={params.subscriptionId!} productId={params.productId!} />
					</Content>
				</GenericLayout>
			</Match>
		</Switch>
	);
}
